<script setup lang="ts">
import type { Appointment } from '~/types/calendar'

const props = withDefaults(
  defineProps<{
    appointment: Appointment
    showPatientName?: boolean
    showDate?: boolean
    showRelativeTime?: boolean
  }>(),
  {
    showPatientName: false,
    showDate: true,
  },
)

const nuxtApp = useNuxtApp()
</script>

<template>
  <div class="rounded-lg p-2 transition-colors duration-300 hover:bg-sky-50">
    <UiBadgeAppointment
      class="mb-3 flex items-center"
      :appointment="props.appointment"
      :show-relative-time="props.showRelativeTime"
      :show-comments-count="true"
    />

    <div v-if="showPatientName" class="mb-1 flex items-center">
      <UAvatar
        class="mr-3"
        :src="props.appointment.patient_profile_picture_small ?? ''"
        :alt="`${props.appointment.patient_first_name} ${props.appointment.patient_last_name}`"
        size="sm"
        loading="lazy"
      />
      <h3 class="truncate text-sm font-medium text-gray-900">
        {{
          `${props.appointment.patient_first_name} ${props.appointment.patient_last_name}`
        }}
      </h3>
    </div>

    <div v-if="showDate" class="flex items-center text-left">
      <UIcon name="i-heroicons-calendar-days-solid" class="mr-2" />
      <p class="text-left text-sm">
        {{ nuxtApp.$dayjs(props.appointment.schedule_start).format('LLLL') }}
      </p>
    </div>
  </div>
</template>
